<template>
  <v-dialog
    v-model="showDialog"
    persistent
    max-width="900"
  >
    <v-card
      :disabled="loading"
      :loading="loading"
    >
      <v-toolbar
        dense
        flat
      >
        <v-toolbar-title>{{ $t('erp.lang_open_order') }}</v-toolbar-title>

        <v-spacer />

        <v-btn
          icon
          @click="closeDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0" />
      <v-card-text class="overflow-y-auto px-0" style="max-height: 400px;">
        <v-container v-if="orders.length === 0 && !loading">
          <v-row>
            <v-col cols="12">
              <v-alert
                text
                color="primary"
                class="text-center"
                >{{ $t('generic.lang_noOrdersFound') }}</v-alert
              >
            </v-col>
          </v-row>
        </v-container>

      <v-simple-table
        v-if="orders.length > 0"
        class="table-striped"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">#</th>
              <th class="text-left">{{ $t('generic.lang_pickUpNumber') }}</th>
              <th class="text-left">{{ $t('generic.lang_Pagernummer') }}</th>
              <th class="text-left">{{ $t('generic.lang_date') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in orders"
              :key="order.id"
            >
              <td>{{ order.tableName }}</td>
              <td>{{ order.pick_up_number || "" }}</td>
              <td>{{ order.pager_number || "" }}</td>
              <td>{{ formatTS(order.timestamp) }}</td>
              <td class="text-right">
                <v-btn
                  color="success"
                  @click="openSavedPreOrder(order)"
                  >{{ $t('generic.lang_open') }}</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      
    </v-card-text>
      <v-card-actions>
        <v-btn
        color="error"
          text
          @click="closeDialog"
          >{{ $t('generic.lang_cancel') }}</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- Delete Warning -->
    <v-dialog
      persistent
      v-model="showDeleteWarning"
      max-width="400"
    >
    <v-card>
        <v-toolbar
        color="#424242"
          dark
          dense
          flat
        >
          <v-toolbar-title>{{ $t('erp.lang_posDeleteOrder') }}</v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="cancelDelete"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center"
              >
                <p style="font-size: 17px">
                  {{ $t('generic.lang_doYouReallyWantTo') }}
                  <span v-if="orderToDelete === null">{{ $t('generic.lang_allOperations') }}</span>
                  <span v-if="orderToDelete !== null">{{ $t('generic.lang_theProcess') }}</span>
                  {{ $t('generic.lang_delete') }}? <br /><br /><b>{{ $t('generic.lang_actionCannotbeUndone') }}</b>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="cancelDelete"
            >{{ $t('generic.lang_cancel') }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="success"
            @click="deleteSavedOrder(orderToDelete, true)"
          >
            {{ $t('generic.lang_delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ENDPOINTS } from '../../../config';
import { Events } from '../../../plugins/events';

export default {
  name: 'ButtonsPreordersDialog',

  props: {
    showDialog: Boolean,
    posType: String,
    item: Object,
  },

  data() {
    return {
      loading: false,
      orders: [],
      orderToDelete: null,
      showDeleteWarning: false,
      tseErrorMessage: '',
    };
  },

  computed: {
    ...mapState(['pos']),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient',
    }),
  },

  watch: {
    showDialog(val) {
      if (val) {
        this.orders = [];

        this.getSavedOrders();
      }
    },
  },

  methods: {
    formatTS(timestamp){
      return this.$moment.unix(timestamp).format("DD.MM.YYYY HH:mm")
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    getSavedOrders() {
      if (this.posType === 'gastro') {
        this.loading = true;
        this.axios
          .post(ENDPOINTS.POS.GASTRO.PREORDER.GETALL, {
            orderID: 0,
          })
          .then((res) => {
            if (res.data.success) {
              this.orders = res.data.preorder;
            }
          })
          .catch(() => {
          }).finally(()=> {
            this.loading = false;
          })
      }
    },
    openSavedPreOrder(order) {
      this.loading = true;

        this.axios
          .post(ENDPOINTS.POS.GASTRO.PREORDER.GETBYTABLENAME, {
            tableName: order.tableName,
          })
          .then((res) => {
            if (res.data.success) {
              this.$store.commit('pos/gastro/setOpenItems', {
                  orders: res.data.preorder.items?.map(item => ({ ...item, isWeighted: false, originalSellPrice: Number(item.originalSellPrice),  sellPrice: Number(item.sellPrice)})) || [],
                  party: {
                    name: res.data.preorder.partyName,
                  },
                });
                
                if(res.data.preorder.pick_up_number)
                  this.$store.commit('pos/gastro/setPickup', res.data.preorder.pick_up_number);

                if(res.data.preorder.pager_number)
                  this.$store.commit('pos/gastro/setPagerNo', res.data.preorder.pager_number);

                this.closeDialog();
            }else {
              
            }
          })
          .catch(() => {
            
          }).finally(()=> {
            this.loading = false;
          })
    },
    deleteSavedOrder(order = null, skipWarning = false) {
      if (!skipWarning) {
        this.showDeleteWarning = true;
        this.orderToDelete = order;
        return;
      }

      this.loading = true;

      this.axios
        .post(ENDPOINTS.POS.RETAIL.ORDERS.DELETE, {
          orderID: order !== null ? order.id : 0,
        })
        .then((res) => {
          if (res.data.success) {
            this.orderToDelete = null;
            this.showDeleteWarning = false;

            this.getSavedOrders();
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    closeTSEErrorDialog() {
      this.tseErrorMessage = '';
      this.cancelDelete();
    },

    cancelDelete() {
      this.orderToDelete = null;
      this.showDeleteWarning = false;
    },
  },
};
</script>
